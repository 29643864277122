import React, { useState } from "react";
import "../Style/Box.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBookReader,
  faChalkboardTeacher,
  faCheck,
  faClose,
  faPlayCircle,
  faPortrait,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Server from "../JsonServer/Server";
import { User, usrSubs } from "../Functions/LocalSaveData";
import { DeProgresBar, VidProgBar } from "./Charts";
import { useNavigate } from "react-router-dom";
import { dateFormat, isUsrEbRd, isUsrEbShelf } from "../Functions/utils";
import { cmdEbook } from "../Functions/ebookFunctions";
import { NewsModal, SubsPlanModal } from "./Modal";
import { remVidRead } from "../Functions/mvpFunctions";
import noImage from "../Assets/ui/student2.png";
import WOC from "../Assets/ui/WOC.jpg";

export const EbookStat = ({ data }) => {
  return (
    <div className="ebStatDiv">
      <div className="ebStatItem">
        <FontAwesomeIcon className="ebStatIcn" icon={faBook} />
        <label className="ebStat">
          {data.ebCount === undefined ? 0 : parseFloat(data.ebCount) * 2}
        </label>
        <label className="ebStatTxt">Ebooks</label>
      </div>
      <div className="ebStatItem">
        <FontAwesomeIcon className="ebStatIcn" icon={faChalkboardTeacher} />
        <label className="ebStat">
          {data.subjects === undefined ? 0 : data.subjects.length}
        </label>
        <label className="ebStatTxt">Disciplines</label>
      </div>
      <div className="ebStatItem">
        <FontAwesomeIcon className="ebStatIcn" icon={faBookReader} />
        <label className="ebStat">
          {data.ebook_active === undefined ? 0 : data.ebook_active.reader}
        </label>
        <label className="ebStatTxt">Active Reader</label>
      </div>
      <div className="ebStatItem">
        <FontAwesomeIcon className="ebStatIcn" icon={faPortrait} />
        <label className="ebStat">
          {data.ebook_active === undefined
            ? 0
            : Math.round(parseFloat(data.ebook_active.total) * 2.4)}
        </label>
        <label className="ebStatTxt">Total Readings</label>
      </div>
    </div>
  );
};

export const EbGnrBox = ({ item, width }) => {
  const { image, name, id } = item;
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`gnr/${id}`)}
      className="gnrItm"
      style={{ width: width === undefined ? "unset" : width }}
    >
      <img alt={name} src={Server.EbGenre + image} className="gnrImg" />
      <label className="gnrTtl">{name}</label>
    </div>
  );
};

export const EbookBox = ({ item, width, setEbItm, readFunc, reRender }) => {
  const { id, image_path, title, author } = item;
  const isUSr = User().id;

  const handleCmd = () => {
    if (isUSr) {
      if (isUsrEbShelf(id)) {
        window.confirm(`Do ou want to remove "${title}" from your shelf`) &&
          cmdEbook(id, "bs", "rem").then(() => reRender());
      } else {
        cmdEbook(id, "bs", "add").then(() => {
          alert(`"${title}" added to your shelf`);
          reRender();
        });
      }
    } else {
      readFunc();
    }
  };
  return (
    <div className={"ebItm " + (width ? "mobEb" : "")}>
      <div className="flip-card" onClick={() => setEbItm(item)}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <img
              src={
                String(image_path).includes("WOC")
                  ? WOC
                  : Server.EbTHUMB + image_path
              }
              alt={title}
              className="ebImg"
            />
          </div>
          <div className="flip-card-back">
            <div style={{ paddingInline: 10 }}>
              <h4>{title}</h4>
              <p>By {author}</p>
            </div>
          </div>
        </div>
      </div>
      <label onClick={() => readFunc(item)} className="ebBtn">
        {isUsrEbRd(id) ? "Resume" : "Read"}
      </label>
      <label onClick={() => handleCmd()} className="ebBtn ebBtn2">
        {isUsrEbShelf(id) ? "Remove from Shelf" : "Add to Shelf"}
      </label>
    </div>
  );
};

export const PrelSubBox = ({ data, title, img, type }) => {
  data = data === undefined ? [] : data;
  return (
    <div className={"plrSuListD " + (type === "st" ? "st" : "wk")}>
      <div className={"plrSubHead tyHead"}>
        <img className="plrSubImg" alt={title} src={img} />
        <p className="plrSubTxt"> {title}</p>
      </div>
      <div className="subList">
        {data.length === 0 ? (
          <div className="subItem tyItm">
            <FontAwesomeIcon icon={faClose} className="subIcn tyIcn" />
            <p className="sub">No Subjects</p>
          </div>
        ) : (
          data.map((item, index) => (
            <div className="subItem tyItm" key={index}>
              <FontAwesomeIcon icon={faCheck} className="subIcn tyIcn" />
              <p className="sub">{item.subject_name}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export const VidBox = ({ item, width, isCw }) => {
  let { video_name, views, thumbnail, topic_name, id, is_free, prcnt } = item;
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  let { payment_status } = User();

  const hndlPlay = () => {
    if (is_free === 0 && payment_status !== 1) {
      setVisible(true);
    } else {
      navigate(`/mvp/${id}/${topic_name ? 0 : 1}`);
    }
  };

  const hndlRmVid = () => {
    let user = User();
    const vidData = {
      usrId: user.id,
      vidId: id,
      freeVid: topic_name ? 0 : 1,
    };
    if (
      window.confirm(
        `Are you Sure!, Do you want to remove ${topic_name ? topic_name : ""} ${
          video_name ? video_name : ""
        } from Continue Watching ?`
      )
    ) {
      remVidRead(vidData);
    }
  };

  return (
    <div
      className="vidItm alt"
      style={{
        width,
      }}
    >
      {visible && (
        <SubsPlanModal
          visible={visible}
          setVisible={() => setVisible(false)}
          msg={`You can't access this topic videos .Please upgrade to gain access to complete Topic Videos.`}
        />
      )}
      <img alt="video" className="playDiv" src={thumbnail} />
      <VidProgBar progress={prcnt} />
      <p className="vidHead">{`${topic_name ? topic_name : ""}
        ${video_name ? video_name : ""} `}</p>

      <div className={"vidBtnDiv"}>
        <div
          onClick={() => hndlPlay()}
          className="vidBtn"
          style={{ display: "flex", alignItems: "center", marginBlock: 10 }}
        >
          <FontAwesomeIcon icon={faPlayCircle} className="btnIcn" />
          &nbsp; {prcnt === 0 ? "Play" : "Resume"}
        </div>
        <div className="vidViews">
          {views} <p className="altView"> Views</p>
        </div>
      </div>
      <FontAwesomeIcon
        icon={faTrashAlt}
        className="remIcn"
        style={{ display: isCw ? "" : "none" }}
        title="Remove"
        onClick={() => hndlRmVid()}
      />
    </div>
  );
};

export const DeSubBox = ({ title, img, type }) => {
  let subStatus = type === "st" ? "strong" : "weak";
  let subs = usrSubs();
  let data = subs.filter((i) => i.status === subStatus);
  return (
    <div className={"plrSuListD altDe " + (type === "st" ? "st" : "wk")}>
      <div className={"plrSubHead tyHead"}>
        <img className="plrSubImg altDeImg" alt={title} src={img} />
        <p className="plrSubTxt"> {title}</p>
      </div>
      <div className="subList">
        {data.length === 0 ? (
          <div className="subItem tyItm">
            <FontAwesomeIcon icon={faClose} className="subIcn tyIcn" />
            <p className="sub">No Subjects</p>
          </div>
        ) : (
          data.map((item, index) => (
            <div className="subItem tyItm deSubItm" key={index}>
              <p className="sub deSub">{item.subject_name}</p>
              <DeProgresBar
                progress={parseFloat(item.subject_prepration)}
                type={type}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export const CaNaBox = ({ item }) => {
  const { title, imgUrl, context, postDate, srcUrl, srcName } = item;
  return (
    <div
      className={"NaItm alt"}
      style={{
        overflowY: "scroll",
        background: `url(${imgUrl})`,
      }}
    >
      <div className="naBtnDiv">
        <img className="naImg" alt="Current Affairs" src={imgUrl} />
      </div>
      <div className="naBdDiv">
        <p className="naHd">{title}</p>
        <p className="naBd">{String(context)}</p>
        <p className="naSrc">Source - {srcName}</p>
        <p className="naDt">{dateFormat(postDate)}</p>
        <div className="naBtnDiv">
          <a className="naBtn" target="_blank" rel="noreferrer" href={srcUrl}>
            More Info.
          </a>
        </div>
      </div>
    </div>
  );
};

export const NwsBox = ({ item }) => {
  const { title, imgUrl, context, postDate } = item;
  const [v, setV] = useState(false);
  const [nwsImg, setNwsImg] = useState(imgUrl);

  return (
    <div className="nwsDiv">
      <NewsModal item={item} setV={() => setV(false)} v={v} />
      <div className="nwsImgCon" style={{ background: `url(${nwsImg})` }}>
        <img
          className="nwsImg"
          alt="Current Affairs"
          src={nwsImg}
          onError={() => setNwsImg(noImage)}
        />
      </div>
      <div className="newsInfoD">
        <p className="nwsHead">{title}</p>
        <p className="nwsBody">
          {String(context).slice(0, 140)}
          ......
        </p>
        <span style={{ alignSelf: "end", fontWeight: "bold" }}>
          {dateFormat(postDate)}
        </span>
        <p
          onClick={() => {
            setV(true);
          }}
          className="NwsBtn"
        >
          Continue Reading
        </p>
      </div>
    </div>
  );
};
