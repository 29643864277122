import React, { useEffect, useState } from "react";
import "../../Style/mvp.scss";
import { mvpDash } from "../../Functions/mvpFunctions";
import {
  ConVidCrsl,
  MvpBannerCrsl,
  SubVidCsrl,
  VidListCrsl,
} from "../../Components/BoxCarousel";
import { DlMdl } from "../../Components/Modal";

export default function MVP() {
  const [data, setData] = useState([]);
  const [dl, setDl] = useState(true);
  useEffect(() => {
    const load = async () => setData(await mvpDash(), setDl(false));
    load();
  }, []);
  const { tpcVidSubs } = data;

  return (
    <div className="HomeD mvpMain">
      <DlMdl v={dl} />
      <MvpBannerCrsl />
      <ConVidCrsl />
      <VidListCrsl />
      <SubVidCsrl vidData={tpcVidSubs} />
    </div>
  );
}
