import React, { useEffect, useState } from "react";
import "../Style/sideBar.scss";
import { getUsrImg, usrLogout } from "../Functions/authFunctions";
import { Drawer } from "@mui/material";
import noImage from "../Assets/ui/student2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleRight,
  faBook,
  faBookReader,
  faFileEdit,
  faFileExcel,
  faHome,
  faInfoCircle,
  faLightbulb,
  faPhone,
  faPodcast,
  faSignOut,
  faSwatchbook,
  faUser,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { usrSubs } from "../Functions/LocalSaveData";
import { User } from "../Functions/LocalSaveData";
import { useNavigate } from "react-router-dom";

export const Sidebar = ({ visible, setVisible }) => {
  const navigate = useNavigate();
  let subs = usrSubs();
  const { name, last_login, last_selected_course, ai_attempt } = User();
  let dateTime = last_login === undefined ? new Date() : new Date(last_login);
  dateTime = dateTime.toString().substring(0, 24);

  const [caMenVsbl, setCaMenVsbl] = useState(false);
  const [subVsbl, setSubVsbl] = useState(false);
  const [prbVsbl, setPrVsbl] = useState(false);
  const [bmVsbl, setBmVsbl] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    const getImage = async () => {
      setImage(await getUsrImg());
    };
    getImage();
  }, []);

  return (
    <Drawer anchor={"left"} open={visible} onClose={() => setVisible()}>
      <div className="sidebar">
        <div className="usrProfile">
          <img
            className="usrImg"
            alt={name}
            src={image === "" ? noImage : image}
            onError={() => setImage(noImage)}
          />
          <label className="usrName">Hi, {name}</label>
          <label className="usrName">
            You last Logined at
            <br /> <span className="alt">{dateTime}</span>
          </label>
          <div className="btnDiv">
            <p onClick={() => navigate(`/up`)} className="proBtn">
              <FontAwesomeIcon icon={faUser} />
              &nbsp; Profile
            </p>
            <p
              className="proBtn"
              onClick={() => {
                if (window.confirm("Are you sure you want to log out")) {
                  usrLogout();
                }
              }}
            >
              <FontAwesomeIcon icon={faSignOut} />
              &nbsp; Logout
            </p>
          </div>
        </div>
        {/* Side Bar Menu Items */}

        <div className="menu resMn" onClick={() => navigate(`/`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faHome} />
              &nbsp; &nbsp; Home
            </p>
          </div>
        </div>

        <div className="menu resMn" onClick={() => navigate(`/au`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faInfoCircle} />
              &nbsp; &nbsp; About Us
            </p>
          </div>
        </div>

        <div className="menu resMn" onClick={() => navigate(`/crs`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faBookReader} />
              &nbsp; &nbsp; Courses
            </p>
          </div>
        </div>

        <div className="menu">
          <div className="menuItmD" onClick={() => setSubVsbl(!subVsbl)}>
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faBook} />
              &nbsp; &nbsp; Subjects
            </p>
            <FontAwesomeIcon
              className="menuOpn"
              icon={subVsbl ? faAngleDown : faAngleRight}
            />
          </div>
          {!subVsbl ? null : (
            <div className="subItm">
              {subs.map(({ subject_name, subject_id }, index) => (
                <p
                  onClick={() => navigate(`/tpc/${subject_id}`)}
                  key={index}
                  className="lstItm"
                >
                  {subject_name}
                </p>
              ))}
            </div>
          )}
        </div>

        <div className="menu resMn" onClick={() => navigate(`/mvp`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faVideo} />
              &nbsp; &nbsp; Video Lectures
            </p>
          </div>
        </div>

        <div className="menu" onClick={() => navigate(`/mt`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faFileEdit} />
              &nbsp; &nbsp; MockTest
            </p>
          </div>
        </div>

        <div className="menu">
          <div className="menuItmD" onClick={() => setPrVsbl(!prbVsbl)}>
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faFileExcel} />
              &nbsp; &nbsp; Progress Reports
            </p>
            <FontAwesomeIcon
              className="menuOpn"
              icon={prbVsbl ? faAngleDown : faAngleRight}
            />
          </div>
          {!prbVsbl ? null : (
            <div className="subItm">
              <p className="lstItm" onClick={() => navigate(`/mt/mp`)}>
                MockTest
              </p>
              <p className="lstItm" onClick={() => navigate(`/tpc/tpr`)}>
                Practice Test
              </p>
              <p
                className="lstItm"
                onClick={() => navigate(`ai/plr/${last_selected_course}`)}
                style={{ display: ai_attempt ? null : "none" }}
              >
                Prelims
              </p>
              <p
                onClick={() => navigate(`ai/der`)}
                className="lstItm"
                style={{ display: ai_attempt ? null : "none" }}
              >
                Detailed Evaluation
              </p>
            </div>
          )}
        </div>

        <div className="menu resMn" onClick={() => navigate(`/eu`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faLightbulb} />
              &nbsp; &nbsp; Exam Updates
            </p>
          </div>
        </div>

        <div className="menu resMn" onClick={() => navigate(`/eb`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faSwatchbook} />
              &nbsp; &nbsp; E-Books
            </p>
          </div>
        </div>

        <div className="menu">
          <div className="menuItmD" onClick={() => setCaMenVsbl(!caMenVsbl)}>
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faNewspaper} />
              &nbsp; &nbsp; Current Affairs
            </p>
            <FontAwesomeIcon
              className="menuOpn"
              icon={caMenVsbl ? faAngleDown : faAngleRight}
            />
          </div>
          {!caMenVsbl ? null : (
            <div className="subItm">
              <p className="lstItm" onClick={() => navigate(`/ca/na/0`)}>
                News paper Analysis
              </p>
              <p className="lstItm" onClick={() => navigate(`/ca/dna`)}>
                Detailed News Analysis
              </p>
              <p className="lstItm" onClick={() => navigate(`/ca/ma`)}>
                Magazine Analysis
              </p>
              <p className="lstItm" onClick={() => navigate(`/ca/pib`)}>
                PIB Govt. Reports
              </p>
            </div>
          )}
        </div>

        <div className="menu">
          <div className="menuItmD" onClick={() => navigate(`/pd`)}>
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faPodcast} />
              &nbsp; &nbsp; Podcast
            </p>
          </div>
        </div>

        <div className="menu">
          <div className="menuItmD" onClick={() => setBmVsbl(!bmVsbl)}>
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faBookmark} />
              &nbsp; &nbsp; Bookmarks
            </p>
            <FontAwesomeIcon
              className="menuOpn"
              icon={bmVsbl ? faAngleDown : faAngleRight}
            />
          </div>
          {!bmVsbl ? null : (
            <div className="subItm">
              <p onClick={() => navigate(`/bm/cb`)} className="lstItm">
                Current Affairs
              </p>
              <p onClick={() => navigate(`/bm/tb`)} className="lstItm">
                Topics
              </p>
            </div>
          )}
        </div>

        <div className="menu resMn" onClick={() => navigate(`/cu`)}>
          <div className="menuItmD">
            <p className="menuItm">
              <FontAwesomeIcon className="menuIcn" icon={faPhone} />
              &nbsp; &nbsp; Contact Us
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export const NaCatSide = ({
  data,
  v,
  setV,
  navigate,
  setGnrNm,
  setGnrId,
  gnrId,
  title,
}) => {
  return (
    <Drawer anchor={"right"} open={v} onClose={() => setV()}>
      <div className="NaSidebar">
        <div className="naCatDiv" style={{ marginTop: 8 }}>
          <p className="tpcTxt">{title}</p>
          <div className="caLst" style={{ maxHeight: "100vh" }}>
            {data.map(({ name, id }, index) => (
              <div
                onClick={() => {
                  navigate(id);
                  // eslint-disable-next-line no-unused-expressions
                  setGnrNm ? setGnrNm(name) : null;
                  setGnrId(id);
                }}
                className={String(gnrId) === String(id) ? "actNaItm" : "naItm"}
                style={{ textAlign: "center" }}
                key={index}
              >
                <p className="naCat"> {name}</p>
              </div>
            ))}
            {/* <p
          style={{ display: type === "ca" ? "unset" : "none" }}
          onClick={() => nav(`/ca/q`)}
          className="rdItm tqBtm"
        >
          Take Quiz
        </p> */}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
